import * as React from "react";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import ScrollMarker from "../components/ScrollMarker";

import "./404.css";

const NotFoundPage = () => {
    const _heroEndMarkerRef = React.useRef<HTMLDivElement>(null);

    return (
        <Layout className="main notFoundPage" scrollMarkerRef={_heroEndMarkerRef}>
            <ScrollMarker ref={_heroEndMarkerRef} />
            <Seo title="Page Not found"/>
            <h1>Page Not Found</h1>
        </Layout>
    );
};

export default NotFoundPage;
